export const tableColumn = [
	{
		label: 'ID',
		prop: 'customerId',
		width: '80px',
	},
	{
		label: '客户姓名',
		prop: 'name',
		width: '120px',
		isCustomize: true,
		tableType: 'name',
	},
	{
		label: '客户电话',
		prop: 'mobile',
		width: '200px',
	},
	{
		label: '客户来源',
		prop: 'sourceName',
		width: '200px',
	},
	{
		label: '跟进人',
		prop: 'username',
		width: '120px',
	},{
		label: '入职时间',
		prop: 'hiredate',
		width: '120px',
	},
	{
		label: '一级部门',
		prop: 'deptName',
		width: '200px',
	},
	{
		label: '上门时间',
		prop: 'createTime',
		
	},
]
export const forms = [
	[
		{
			label: '客户姓名',
			type: 'input',
			attr: 'name',
			placeholder: '请输入客户姓名',
			column: 6,
			labelWidth: '96px',
		},
		{
			label: '手机账号',
			type: 'input',
			attr: 'mobile',
			placeholder: '请输入手机账号',
			column: 6,
			labelWidth: '96px',
		},

		{
			label: '年龄',
			type: 'input',
			attr: 'age',
			inputType: 'number',
			placeholder: '请输入年龄',
			column: 6,
			labelWidth: '96px',
		},
		{
			label: '性别',
			type: 'radio',
			attr: 'sex',
			placeholder: '请选择',
			column: 6,
			labelWidth: '96px',
			option: [
				{ label: '男', value: 0 },
				{ label: '女', value: 1 },
			],
		},
	],
	[
		{
			label: '职业',
			type: 'select',
			attr: 'occupationId',
			placeholder: '请选择',
			column: 6,
			labelWidth: '96px',
			option: [],
		},
		{
			label: '学历',
			type: 'select',
			attr: 'educationId',
			placeholder: '请输选择',
			column: 6,
			labelWidth: '96px',
			option: [],
		},
		{
			label: '婚姻状态',
			type: 'radio',
			attr: 'marriageStatus',
			placeholder: '请选择',
			column: 6,
			labelWidth: '96px',
			option: [
				{ label: '已婚', value: 1 },
				{ label: '未婚', value: 0 },
			],
		},
		{
			label: '是否签约',
			type: 'radio',
			attr: 'contractStatus',
			placeholder: '请选择',
			column: 6,
			labelWidth: '96px',
			option: [
				{ label: '是', value: 1 },
				{ label: '否', value: 0 },
			],
		},
	],
	[
		{
			label: '签约时间',
			type: 'date',
			attr: 'contractTime',
			placeholder: '请选择',
			column: 6,
			labelWidth: '96px',
		},
		{
			label: '贷款额度',
			type: 'input',
			inputType: 'number',
			labelWidth: '96px',
			attr: 'loanLimit',
			placeholder: '请输入贷款额度',
			column: 6,
		},
		{
			label: '年收入',
			type: 'input',
			inputType: 'number',
			labelWidth: '96px',
			attr: 'annualIncome',
			placeholder: '请输入年收入',
			column: 6,
		},
	],
]

export const radioForms = [
	[
		{
			label: '房产',
			type: 'radioButton',
			attr: 'room',
			column: 12,
			labelWidth: '120px',
			option: [
				{ label: '有房产', value: 1 },
				{ label: '无房产', value: 0 },
			],
		},
		{
			label: '公积金',
			type: 'radioButton',
			attr: 'accumulation',
			column: 12,
			labelWidth: '120px',
			option: [
				{ label: '有公积金', value: 1 },
				{ label: '无公积金', value: 0 },
			],
		},
	],
	[
		{
			label: '社会保险',
			type: 'radioButton',
			attr: 'social',
			column: 12,
			labelWidth: '120px',
			option: [
				{ label: '有社会保险', value: 1 },
				{ label: '无社会保险', value: 0 },
			],
		},
		{
			label: '车辆',
			type: 'radioButton',
			attr: 'car',
			column: 12,
			labelWidth: '120px',
			option: [
				{ label: '有车辆', value: 1 },
				{ label: '无车辆', value: 0 },
			],
		},
	],
	[
		{
			label: '企业纳税',
			type: 'radioButton',
			attr: 'enterprise',
			column: 12,
			labelWidth: '120px',
			option: [
				{ label: '有企业纳税', value: 1 },
				{ label: '无企业纳税', value: 0 },
			],
		},
		{
			label: '保单',
			type: 'radioButton',
			attr: 'policy',
			column: 12,
			labelWidth: '120px',
			option: [
				{ label: '有保单', value: 1 },
				{ label: '无保单', value: 0 },
			],
		},
	],
	[
		{
			label: '营业执照',
			type: 'radioButton',
			attr: 'business',
			column: 12,
			labelWidth: '120px',
			option: [
				{ label: '有营业执照', value: 1 },
				{ label: '无营业执照', value: 0 },
			],
		},
		{
			label: '代发',
			type: 'radioButton',
			attr: 'issued',
			column: 12,
			labelWidth: '120px',
			option: [
				{ label: '有代发', value: 1 },
				{ label: '无代发', value: 0 },
			],
		},
	],
	[
		{
			labelCustom: true,
			label: '跟进状态',
			labelWidth: '120px',
			type: 'select',
			attr: 'status',
			option: [],
			column: 12,
			placeholder: '请选择',
		},
		{
			labelCustom: true,
			label: '客户星级',
			labelWidth: '120px',
			type: 'select',
			attr: 'starStatus',
			option: [],
			column: 12,
			placeholder: '请选择',
		},
	],
	[
		{
			label: '跟进备注',
			labelWidth: '120px',
			type: 'input',
			attr: 'followRemark',
			inputType: 'textarea',
			column: 24,
			placeholder: '请输入跟进备注',
		},
	],
	[
		{
            label: ' ',
			labelWidth: '120px',
			type: 'radioGroup',
			attr: 'followId',
			column: 24,
			option: [],
		},
	],
	[
		{
			label: '客户备注',
			labelWidth: '120px',
			attr: 'remark',
			type: 'input',
			inputType: 'textarea',
			column: 24,
			placeholder: '请输入客户备注',
		},
	],
]
