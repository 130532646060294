<template>
  <div class="xinsheng-warp system-warp">
    <FilterForm
      :formData="filterData"
      :filters="filters"
      @changeDept="changeDept"
      @submit="handleSubmit"
      @reset="handleReset"
    />

    <div class="system-btn user-btn">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="handleNew"
      >来访登记</el-button
      >
    </div>

    <CustomerTable
      v-if="tableData"
      :tableColumn="tableColumn"
      :tableData="tableData"
      @nameDrawer="handleNameDrawer"
    />
    <CustomerPagination
      :total="total"
      @currentChange="currentChange"
      @sizeChange="sizeChange"
    />
    <CustomerDetail
      width="1200px"
      :isShowDialog="isShowDialog"
      @close="handleClose"
      direction="rtl"
      :userId="userId"
      @next="handleNextCustomer"
      @prev="handlePrevCustomer"
    />
  </div>

</template>

<script>
import FilterForm from "@/components/filter";
import { computed, onMounted, reactive, ref } from "vue";
import { tableColumn } from "./index.js";
import CustomerTable from "@/components/table";
import CustomerPagination from "@/components/pagination";
import { useStore } from "vuex";
import { getUserByDept } from "@/api/method/common";
import CustomerDetail from "@/components/drawer-form/index.vue";
import { reset } from "@/until";
import { ElMessage ,ElButton} from "element-plus";

export default {
  name: "ClientVisit",
  components: {
    [ElButton.name]: ElButton,
    FilterForm,
    CustomerTable,
    CustomerPagination,
    CustomerDetail,
  },
  setup() {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      deptId: null,
      userId: null,
      search: null,
      date: null,
    });
    const filters = reactive([
      [
        {
          label: "选择部门",
          type: "select",
          attr: "deptId",
          placeholder: "请选择",
          column: 8,
          option: [],
          labelWidth: "80px",
        },
        {
          label: "选择顾问",
          type: "select",
          attr: "userId",
          placeholder: "请选择",
          column: 8,
          option: [],
          labelWidth: "100px",
        },
        {
          label: "客户",
          type: "input",
          attr: "search",
          placeholder: "搜索客户手机号或姓名",
          suffixIcon: "el-icon-search",
          column: 8,
          labelWidth: "100px",
        },
      ],
      [
        {
          label: "上门时间",
          type: "dateRange",
          attr: "date",
          column: 8,
          labelWidth: "80px",
          valueFormat: "YYYY-MM-DD",
        },
        {
          type: "button",
          column: 16,
        },
      ],
    ]);

    const pagination = reactive({
      page: 1,
      size: 10,
    });

    const tableData = computed(
      () => state.customerVisit && state.customerVisit.records
    );
    const total = computed(
      () => state.customerVisit && state.customerVisit.total
    );
    const getDepartList = async () => {
      await dispatch("departmentList");
      filters[0][0].option = state.departmentList.map((item) => ({
        label: item.fullName,
        val: item.id,
      }));
    };
    const handleNew = () => {
      window.open("/customer/visit");
    };
    const changeDept = async (id) => {
      const res = await getUserByDept(id);
      filters[0][1].option = res.data.map((item) => ({
        label: item.nickname,
        val: item.id,
      }));
    };

    const getList = () => {
      const { date, ...param } = filterData;

      const startTime = date && date[0];
      const endTime = date && date[1];

      dispatch("customerVisit", {
        ...pagination,
        ...param,
        startTime,
        endTime,
      });
    };

    onMounted(() => {
      getList();
      getDepartList();
    });

    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const sizeChange = (size) => {
      pagination.size = size;
      getList();
    };

    const handleSubmit = () => getList();
    const handleReset = () => {
      reset(filterData);
      getList();
    };
    const isShowDialog = ref(false);
    const handleClose = () => (isShowDialog.value = false);
    const userId = ref(null);
    const handleNameDrawer = (row) => {
      isShowDialog.value = true;
      userId.value = row.customerId;
    };
    const handleNextCustomer = () => {
      const index =
        state.customerVisit.records.findIndex(
          (item) => item.customerId == userId.value
        ) + 1;
      if (index == state.customerVisit.records.length) {
        ElMessage.error("已经是最后一个客户了！");
        return;
      }
      userId.value = state.customerVisit.records[index].customerId;
    };
    const handlePrevCustomer = () => {
      const index =
        state.customerVisit.records.findIndex(
          (item) => item.customerId == userId.value
        ) - 1;
      if (index < 0) {
        ElMessage.error("已经是第一个客户了！");
        return;
      }
      userId.value = state.customerVisit.records[index].customerId;
    };

    return {
      filters,
      filterData,
      tableColumn,
      tableData,
      total,
      changeDept,
      currentChange,
      sizeChange,
      handleSubmit,
      handleReset,
      handleNew,
      isShowDialog,
      handleClose,
      handleNameDrawer,
      userId,
      handleNextCustomer,
      handlePrevCustomer,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-btn {
  padding-bottom: 20px;
}
.container {
  display: flex;
  height: auto;
  //   min-height: 100%;
  .left {
    width: 250px;
    background: #f5f6f9;
    margin-right: 24px;
    padding: 20px;
  }
  .right {
    flex: 1;
    background-color: #fff;
    overflow: hidden;
  }
}
</style>

<style lang="scss">
.company-block {
  margin-bottom: 20px;
}

.company-block .el-input__inner {
  background: white !important;
}
.depart-tree {
  background: #f5f6f9;
  .el-tree-node__content {
    background: #f5f6f9;
  }

  .el-tree-node.is-current > .el-tree-node__content {
    background-color: #406fff;
    color: white;
  }
}
</style>